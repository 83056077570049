.button-primary {
    background-color: var(--main-green);
    padding: 0.5em 2em;
    border-radius: var(--radius);
    border: none;
    color: #FFF;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.button-primary:hover {
    background-color: #009092;
    transition: 0.5s;
}


.button-primary:focus {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 1px #00b9bc;
    transition: 0.5s;
}

.button-primary:active {
    background-color: #00b9bc;
    transition: 0.4s;

}