.banner_header_dasboard_cfl {
   /* width: 90%; */
   min-height: 18rem;
   height: 100%;
   max-height: 23.1rem;
   flex-shrink: 0;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   flex-direction: column;
   /* bg */
   background: url(../../../assets/img/HeroCFL2.jpg) no-repeat;
   background-size: 100%;
   background-position: left top;
   /* Img effect */
   box-shadow: -18rem -90px 250px 150px #142338 inset;
   margin-bottom: 2.7rem;
   /* margin */
   /* padding: 0px 150px 0px 0px; */
   /* margin: 56px 0px 0px 72px; */
   /* border: 6px solid red; */
}

.filter_leader {
   display: flex;
   align-items: center;
   justify-content: start;
}

.section_LEADERBOARD {
   display: flex;
   /* height: 47vh; */
   /* width: 82%; */
   overflow-y: scroll;
   overflow-x: hidden;
   transition: margin 150ms ease-in-out;
   /* margin: 56px 0px 0px 72px; */
}

.section_LEADERBOARD::-webkit-scrollbar {
   width: 8px;
   /* Ancho del scroll bar */
}

.section_LEADERBOARD::-webkit-scrollbar-thumb {
   background-color: var(--stroke-card);
   /* Color del thumb */
   border-radius: 5px;
   /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.section_LEADERBOARD::-webkit-scrollbar-track {
   background-color: transparent;
   /* Fondo transparente del carril */
}

.section_LEADERBOARD::-webkit-scrollbar-thumb:hover {
   background-color: var(--blue-text);
   /* Color del thumb al pasar el cursor por encima */
}

.container_LEADERBOARD {
   width: 80%;
}

.list_user_leader {
   width: 100%;
   margin-top: 2em;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1em;
}

.user_item_leader {
   width: 155px;
}
