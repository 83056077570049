.signup--subcontainer {
   display: flex;
   justify-content: space-between;
}

.signup--col {
   width: 48%;
}

input[type="date"] {
   font-size: 1.3em;
   font-weight: 300;
   padding-bottom: 0.27rem;
}

.__submit {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 3.9rem;
   border-radius: var(--radius);
   border: 2px solid var(--main-green);
   font-size: 1.2rem;
   font-weight: 700;
   color: var(--main-green);
   background-color: var(--section);
   margin-top: 1.75rem;
}

/*MOBILE VIEW*/
@media (max-width: 1023px) {
   .signup--subcontainer {
      display: flex;
      flex-direction: column;
   }
   .singup--form {
      height: 50vh;
      /* padding-right: 1rem; */
      overflow-y: scroll;
   }
   .singup--form::-webkit-scrollbar {
      width: 8px; /* Ancho del scroll bar */
   }

   .singup--form::-webkit-scrollbar-thumb {
      background-color: var(--stroke-card); /* Color del thumb */
      border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
   }

   .singup--form::-webkit-scrollbar-track {
      background-color: transparent; /* Fondo transparente del carril */
   }

   .singup--form::-webkit-scrollbar-thumb:hover {
      background-color: var(
         --blue-text
      ); /* Color del thumb al pasar el cursor por encima */
   }

   .__submit {
      margin-bottom: 1.2rem;
   }
}

/*medium screen resposive*/
@media (min-width: 1024px) and (max-width: 1440px) {
   .signup--subcontainer {
      display: flex;
      flex-direction: row;
   }
   .singup--form {
      height: 50vh;
      padding-right: 1rem;
      overflow-y: scroll;
   }
   .singup--form::-webkit-scrollbar {
      width: 8px; /* Ancho del scroll bar */
   }

   .singup--form::-webkit-scrollbar-thumb {
      background-color: var(--stroke-card); /* Color del thumb */
      border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
   }

   .singup--form::-webkit-scrollbar-track {
      background-color: transparent; /* Fondo transparente del carril */
   }

   .singup--form::-webkit-scrollbar-thumb:hover {
      background-color: var(
         --blue-text
      ); /* Color del thumb al pasar el cursor por encima */
   }

   .__submit {
      margin-bottom: 1.2rem;
   }
}

@media (max-width: 820px) {
   .signup--subcontainer {
      flex-direction: row;
   }

   .signup--col {
      width: 48%;
   }
}

@media (max-width: 576px) {
   .signup--subcontainer {
      flex-direction: column;
   }

   .singup--form {
      height: initial;
      font-size: 1rem;
   }

   .signup--col {
      width: 100%;
   }
}
