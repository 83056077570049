.SeasonSetForm{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.SeasonSetForm_title{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-end;
    flex-direction: row;
    gap: 1rem;
}
.SeasonSetForm_form{
    width: 96%;
    height: 100%;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.SeasonSetForm_block{
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}


.SeasonSetForm_btnContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;

}
.SeasonSetForm_button{
    width: 100%;
    height: 100%;
}

@media (max-width: 414px){
    .SeasonSetForm_form{
        width: 96%;
        height: 100%;
        margin-right: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }
    
    .SeasonSetForm_block{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}