.AsideUser_Card {
   display: flex;
   width: 100%;
   min-width: 100%;
   padding: 0px 1rem;
   justify-content: center;
   align-items: center;
   /* flex-shrink: 0; */
   flex-direction: column;
}

.AsideUser_h4 {
   /* Esta clase se debe asignar a los estilos globales */
   font-family: Roboto;
   font-size: 1.2em;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   letter-spacing: 0.25px;
}

.AsideUser_UserInfo {
   display: flex;
   padding: 4px 0px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 3px;
}

.AsideUser_UserStatus {
   display: flex;
   justify-content: space-between;
   width: 100%;
   align-items: center;
   gap: 3px;
}

.AsideUser_Membership {
   display: flex;
   height: auto;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 1px;
   flex-shrink: 0;
}

.AsideUser_pts {
   color: var(--main-green);
}
.AsideUser_TertiarySubtitle {
   /* width: 100%; */
   text-align: right;
}
