.HeroCFL {
   min-height: 18rem;
   height: 100%;
   max-height: 23.1rem;
   flex-shrink: 0;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   flex-direction: column;
   /* bg */
   background: url(../../../assets/img/HeroCFL.jpg) no-repeat;
   background-size: 100%;
   background-position: -236px -12vh;
   /* Img effect */
   box-shadow: -18rem -120px 250px 150px #142338 inset;
   transition: margin 150ms ease-in-out;
}

@media (max-height: 992px) {
   .HeroCFL {
      box-shadow: -15rem -1rem 250px 120px #142338 inset;
   }
}

@media (max-width: 576px) {
   .HeroCFL {
      box-shadow: 0 -30px 210px 90px #142338 inset,
         0 -15px 60px 30px #142338 inset;

      object-fit: cover;
      background-size: 100vh;
      background-position: center -6vh;
      padding-right: 2.1rem;
   }
}
