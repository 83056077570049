.AdminUserCard_Card {
   display: flex;
   width: 100%;
   padding: 0px 16px;
   justify-content: center;
   align-items: center;
   gap: 10px;
   flex-shrink: 0;
}

.AdminUserCardr_h4 {
   /* Esta clase se debe asignar a los estilos globales */
   width: 131px;
   font-family: Roboto;
   font-size: 30px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   letter-spacing: 0.25px;
}

.AdminUserCard_UserInfo {
   display: flex;
   padding: 4px 0px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 3px;
}

.AdminUserCard_Membership {
   display: flex;
   width: 125px;
   height: 61px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   flex-shrink: 0;
}

.AdminUserCard_TertiarySubtitle {
   width: 100%;
   text-align: right;
   margin: 0px;
}
