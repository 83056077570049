.accordion_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1.5em;
    border-radius: 9px 9px 0px 0px;
    background: radial-gradient(223.22% 177.30% at 11.16% 16.06%, #122033 0%, #305385 100%);
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
}

.accordion_item {
    border-radius: 9px;
    overflow: hidden;
    margin-bottom: 1em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.accordion_content_item {
    cursor: pointer;
    background: #2E425D;
    color: var(--global-font, #E6EBF4);
    margin: 0;
    padding: 0.5em 1.5em;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: var(--radius);
}

.accordion_content_item_selected {

    background: #2E425D;
    color: var(--global-font, #E6EBF4);
    margin: 0;
    padding: 0.5em 1.5em;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    border: var(--main-green) 1px solid;
    border-radius: var(--radius);

}