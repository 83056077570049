button {
   background-color: var(--main-green);
   border: none;
   border-radius: var(--radius);
   padding-top: 0.3rem;
   margin-top: 1.5rem;
   /* left: 0; */
   font-size: 1.2rem;
   /* text-transform: uppercase; */
   height: inherit;
   color: var(--global-font);
   font-weight: 700;
   /* width: 100%; */
}

.login--btn {
   height: 3.9rem;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--main-green);
   border: none;
   border-radius: var(--radius);
   padding-top: 0.3rem;
   margin-top: 1.5rem;
   font-size: 1.2rem;
   height: 3.9rem;
   color: var(--global-font);
   font-weight: 700;
}

.logout--btn {
   padding: 0.3rem 1.5rem;
   background-color: var(--bg-color);
   border: var(--main-green) 1px solid;
   color: var(--main-green);
   margin-top: 0;
}

.add--btn {
   border-radius: var(--radius);
   border: 1px solid var(--global-font-o);
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      rgb(62 206 208 / 0.54) 0%,
      rgb(0 143 145 / 0.54) 60%
   );
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.66rem;
   margin-top: 0;
}
