.Overline {
   font-family: Arimo;
   font-size: 10px !important;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 1.5px;
   text-transform: uppercase;
}

.Overline-2 {
   font-size: 0.6rem;
}

.Overline-3 {
   font-size: 0.9rem !important;
}

.Overline-4 {
   font-size: 0.57rem !important;
}
