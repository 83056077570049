.PlayerAssingForm {
        width: 100%;
        padding: 1rem;
        gap: 2rem;
}

.PlayerAssingForm_container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 3rem;
}

.PlayerAssingForm_form {
        width: 80%;

}

.CreateConferenceForm_block {
        width: 50%;
}

.-click {
        cursor: pointer;
}

.-selected {
        border-color: var(--main-green);
        border-radius: 9px;
}

.-selectedOndivision {
        border-color: var(--global-font);
        border-radius: 9px;
}

.slide-in-blurred-left {
        -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.content_user_division_asing {
        min-height: 75vh;
        max-height: 72vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
}

.content_user_division_asing::-webkit-scrollbar {
        width: 8px;
        /* Ancho del scroll bar */
}

.content_user_division_asing::-webkit-scrollbar-thumb {
        background-color: var(--stroke-card);
        /* Color del thumb */
        border-radius: 5px;
        /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.content_user_division_asing::-webkit-scrollbar-track {
        background-color: transparent;
        /* Fondo transparente del carril */
}

.content_user_division_asing::-webkit-scrollbar-thumb:hover {
        background-color: var(--blue-text);
        /* Color del thumb al pasar el cursor por encima */
}

@-webkit-keyframes slide-in-blurred-left {
        0% {
                -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
                transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
                -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
        }

        100% {
                -webkit-transform: translateX(0) scaleY(1) scaleX(1);
                transform: translateX(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
        }
}

@keyframes slide-in-blurred-left {
        0% {
                -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
                transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
                -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
        }

        100% {
                -webkit-transform: translateX(0) scaleY(1) scaleX(1);
                transform: translateX(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
        }
}


@media (max-width: 416px) {
        .PlayerAssingForm_container {
                flex-direction: column;
        }

        .PlayerAssingForm_form {
                width: 100%;
                position: relative;
                right: 0rem;
                bottom: 0rem;
        }

        .PlayerCard {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 1rem;
                padding: 1rem;
                /* gap: 2rem; */
        }

        .DefaultCardContainer_Title {
                width: 90%;
        }
}