/* .img_login {
   width: 100%;
   height: 100vh !important;
   background-position: center;
   background-repeat: no-repeat;
   background: url("../../../assets/img/BSAlogin.png"),
      lightgray 50% / cover no-repeat; */
/* Do not repeat the image */
/* background-size: cover;
} */

/* .img_register {
   width: 100%;
   height: 100vh !important;
   background-position: center;
   background-repeat: no-repeat;
   background: url("../../../assets/img/sws_register_img.png"),
      lightgray 50% / cover no-repeat; */
/* Do not repeat the image */
/* background-size: cover;
} */

.form-control {
   border-radius: 9px;
   background: var(--wrapper, #162b47);
   color: #fff;
   border: 0;
   padding: 1em;
}

.form-control::placeholder {
   color: var(--global-font);
   opacity: 1;
}

.form-control:focus {
   border-radius: 9px;
   background: var(--wrapper, #162b47);
   color: #fff;
   box-shadow: none !important;
}

label {
   color: #fff;
   background-color: transparent !important;
}

.btn_login {
   border-radius: 9px;
   padding: 0.8em 0 !important;
   background: var(--clickable, #008285);
   color: var(--global-font);
}

.btn_login:hover,
.btn_login:active {
   border-radius: 9px !important;
   padding: 0.8em 0 !important;
   background: var(--clickable, #008385bb) !important;
   color: var(--global-font) !important;
   border: 0 !important;
}

.btn_register {
   border-radius: 9px;
   padding: 0.8em 0 !important;
   border: 2px solid var(--clickable, #008285);
   background: #052440;
   color: #008285;
}

.btn_register:hover,
.btn_register:active {
   border-radius: 9px !important;
   padding: 0.8em 0 !important;
   border: 2px solid var(--clickable, #008285) !important;
   background: #052440 !important;
   color: #008285 !important;
}

.btn_register:active,
.btn_login:active {
   box-shadow: 2px 2px 5px #008285 !important;
}

.terms {
   color: #008285;
   text-decoration: underline;
}
