.select_pick {
    margin-right: 1em;
}

.select_pick_btn {
    padding: 0.8em;
    border-radius: var(--radius);
    background: var(--card-bg, radial-gradient(223.22% 177.30% at 11.16% 16.06%, #122033 0%, #305385 100%));
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selec_pick_item {
    display: block;
    padding: 0.5em;
    white-space: nowrap;
    cursor: pointer;
    
}

.selec_pick_item_selected {
    display: block;
    padding: 0.5em;
    white-space: nowrap;
    cursor: pointer;
    border-radius: var(--radius);
    border: var(--main-green) 1px solid;
}

.selec_pick_item:hover {
    color: var(--main-green);
}


.select_pick_content {
    margin: 0.5em;
    background: var(--card-bg, radial-gradient(223.22% 177.30% at 11.16% 16.06%, #122033 0%, #305385 100%));
    padding: 1em;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.select_pick_item_team {
    cursor: pointer;

    display: block;
    background-color: #122033;
    padding: 0.5em;
    border-radius: var(--radius);
    margin-bottom: 0.5em;
}

.select_pick_item_team_selected {
    display: block;
    background-color: #122033;
    padding: 0.5em;
    border-radius: var(--radius);
    border: var(--main-green) 1px solid;
    margin-bottom: 0.5em;
}