html {
   --global-font: #e6ebf4;
   --global-font-o: rgb(230 235 244 / 0.06);
   --bg-color: #142338;
   --bg-color-o: rgb(20 35 56 / 0.96);
   --bg-color-o2: rgb(20 35 56 / 0.39);
   --radius: 0.6rem;
   --main-green: #008285;
   --section: #162b47;
   --section-o: rgb(22, 43, 71);
   --wrapper-width: 72rem;
   --blue-text: #477bc4;
   --stroke-card: #305385;
   --red-text: #cf370a;
   --red-text-o: rgb(207 56 10 / 0.6);
   --aside-width: 330px;
   --closed-aside-width: 90px;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: var(--global-font);
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

::-webkit-scrollbar {
   width: 8px; /* Ancho del scroll bar */
}

::-webkit-scrollbar-thumb {
   background-color: var(--stroke-card); /* Color del thumb */
   border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

::-webkit-scrollbar-track {
   background-color: var(--bg-color); /* Fondo transparente del carril */
}

::-webkit-scrollbar-thumb:hover {
   background-color: var(
      --blue-text
   ); /* Color del thumb al pasar el cursor por encima */
}
