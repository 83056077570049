nav {
   margin-top: 3rem;
   display: flex;
   align-items: center;
   justify-content: center;
   width: inherit;
}

.nav--menu {
   display: flex;
   flex: 1;
   align-items: center;
   z-index: 1;
   justify-content: flex-end;
}

.nav--social {
   display: flex;
   flex: 1;
}

@media (min-width: 1024px) and (max-width: 1440px) {
   .Navbar {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0rem 2rem;
   }
}

@media (max-width: 1023px) {
   .Navbar {
      height: 11vh;
      margin: 0;
      padding: 0rem 1rem;
      position: fixed;
      z-index: 20;
      top: 0;
      background-color: var(--bg-color);
   }

   .nav--logo {
      position: relative;
      z-index: 10;
   }

   .nav--menu {
      position: relative;
      z-index: 10;
   }

   .nav--social {
      width: 100%;
      height: 9rem;
      position: fixed;
      /* bottom: 0; */
      align-self: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 5;
      background-color: var(--bg-color-o);
      top: 9rem;
   }

   .main-logo {
      height: 1.5rem;
   }

   .nav--icoContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      z-index: 5;
   }

   .nav--ico {
      color: white;
      width: 38px;
      height: 38px;
      background: url(../../../assets/svg/Aside_open.svg);
      background-repeat: no-repeat;
   }
   .pulsate-fwd {
      animation: pulsate-fwd 0.5s ease-in-out infinite both;
   }

   @keyframes pulsate-fwd {
      0% {
         transform: scale(1);
      }
      50% {
         transform: scale(1.1);
      }
      100% {
         transform: scale(1);
      }
   }

   .slide-in-top {
      animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
   }

   @keyframes slide-in-top {
      0% {
         transform: translateY(-1000px);
         opacity: 0;
      }

      100% {
         transform: translateY(0);
         opacity: 1;
      }
   }

   .slide-out-top {
      animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
   }

   @keyframes slide-out-top {
      0% {
         transform: translateY(0);
         opacity: 1;
      }

      100% {
         transform: translateY(-1000px);
         opacity: 0;
      }
   }
}

@media (max-width: 820px) {
   .nav--logo {
      left: 5.1rem;
   }

   .main-logo {
      height: 3.9rem;
   }

   /* .nav--menu {
      margin-right: 0.9rem;
   } */

   .nav--icoContainer {
      margin-right: 5.1rem;
   }
}

@media (max-width: 700px) {
   .nav--logo {
      position: absolute;
      top: 0;
      left: 1.8rem;
      margin-right: 3rem;
   }

   .main-logo {
      height: 3.15rem;
      margin: 1.5rem 0;
   }

   .Navbar {
      height: 9rem;
   }

   .nav--menu {
      position: absolute;
      margin-top: 5.4rem;
      display: flex;
      justify-content: center;
      width: 100%;
   }

   .nav--icoContainer {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: end;
      width: 84%;
      margin-right: 0;
   }

   .nav--ico {
      margin: 1.92rem 0;
   }
}
