.home_container {
   display: inline-flex;
   height: 1117px;
   padding-right: 0px;
   align-items: flex-end;
   background: var(--background, #142338);
}

.aside_home {
   display: flex;
   width: 355px;
   padding: 0px 16px;
   flex-direction: column;
   align-items: center;
   gap: 16px;
}

.card_home {
   border-radius: 9px;
   background: var(--wrapper, #162b47);
   padding: 1em 2em;
   margin-top: 7.2rem;
   height: auto;
   background: linear-gradient(#162b47, #162b47) padding-box,
      linear-gradient(to bottom, var(--main-green), var(--section)) border-box;
   border: 3px solid transparent;
}

.nav_home_layout {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0 3em;
   margin-top: 1em;
}

.nav_menu_home_layout {
   display: flex;
   justify-content: center;
}

.container_packs_league {
   background-color: #203654;
   display: flex;
   gap: 1.5em;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: center;
   padding: 0.9rem 1.8em;
   border-radius: var(--radius);
   font-size: 0.99rem;
}

@media (min-width: 1024px) {
   .container-cx {
      max-width: 81%;
   }

   .card_home {
      min-height: 36rem;
   }
}

@media (max-width: 576px) {
   .nav_home_layout {
      flex-direction: column;
   }

   .card_home {
      margin-top: 5.1rem;
      padding: 1em 0.6rem;
   }

   .container_packs_league {
      padding: 0.9rem 0.6em;
   }

   .card_packs_league {
      gap: 0.9rem;
   }
}
