.HeroCFLSeasonInfo_Season {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: end;
   margin: 10px 0px 10px 0px;
   gap: 6px;
}

.HeroCFLSeasonInfo_status {
   display: flex;
   flex-direction: row;
   gap: 16px;
}

.HeroCFLSeasonInfo_seasonType {
   color: var(--main-green);
}
