.CardTeams {
    display: flex;
    width: 23.696rem;
    height: 5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.2rem;
}

.CardTeams_column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    flex-basis: 100%;
}

.CardTeams_name {
    flex-basis: 100%;
    text-align: left;
    margin: 0px;
}
.CardTeams_teamData{
    width: auto;
    justify-content: center;
    align-items: center;
}

.CardTeams_pts {
    height: fit-content;
    display: flex;
    flex-basis: 100%;
    flex-direction: Row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;
}

.CardTeams_body {
    padding: 0;
    margin: 0;
    /* Body 2 */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
}

.CardTeams_acronym{
    width: 6rem;
    margin: 0px;
    padding: 0.5rem;
    background-color: var(--section);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.-active{
    color: var(--main-green);
    margin: 0;
}
.-noMargin{
    margin: 0;
}
.LeaderBoard_vs{
    margin: 0;
}