.overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.container_modal {
    width: 70rem;
    min-height: 300px;
    background: var(--wrapper, #162B47);
    position: relative;
    border-radius: var(--radius);
    overflow: hidden;

}

.modal_body {
    padding: 1em;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modal_body::-webkit-scrollbar {
    width: 8px;
    /* Ancho del scroll bar */
}

.modal_body::-webkit-scrollbar-thumb {
    background-color: var(--stroke-card);
    /* Color del thumb */
    border-radius: 5px;
    /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.modal_body::-webkit-scrollbar-track {
    background-color: transparent;
    /* Fondo transparente del carril */
}

.modal_body::-webkit-scrollbar-thumb:hover {
    background-color: var(--blue-text);
    /* Color del thumb al pasar el cursor por encima */
}

.btn_close_modal {
    cursor: pointer;
}

.btn_close_modal:hover i {
    color: var(--main--green);
}

.header_modal {
    padding: 1em 1em 0 0;
    display: flex;
    align-items: end;
    justify-content: end;
}