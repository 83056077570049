@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/arimo");
#root {
   color: var(--global-font);
}

* {
   font-family: "Roboto", sans-serif;
}

a {
   text-decoration: none;
   color: inherit;
}

.header-social a i {
   line-height: 30px;
}

.text-light {
   color: var(--global-font) !important;
}

.header_top {
   padding: 0.5em;
   background-color: #142338;
}

.btn_login_landing {
   border-radius: 9px 0px 0px 9px;
   background: var(--clickable, #008285);
}

.btn_register_landing {
   border-radius: 0px 9px 9px 0px;
   border: 1px solid var(--clickable, #008285);
}

.text-dark {
   color: #142338 !important;
}

.footer {
   margin-top: 3em;
   background-color: #162b47;
   border-top: 1px solid #477bc4;
   padding-top: 1em;
   padding-bottom: 1.2em;
}

.copyright {
   text-align: center;
   font-size: 0.9em;
}

.h-center {
   margin: 0 auto;
   display: flex;
   justify-content: center;
   text-align: center;
   width: 97%;
}

.blue-text {
   color: var(--blue-text);
}

.red-text {
   color: var(--red-text);
}

.light-text {
   font-weight: 300;
}

.separator {
   height: 0.6rem;
}

.separatorLB {
   height: 6rem;
}

.bold {
   font-weight: 500 !important;
}

.overlay {
   position: relative;
   z-index: 9 !important;
}

@media (max-width: 1023px) {
   .footer {
      width: 100%;
   }
   .align-items-center {
      align-items: center !important;
   }
}
