.wrapper {
   width: var(--wrapper-width);
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin: auto;
}

/*Medium Screen*/

@media (max-width: 1024px) {
   .wrapper {
      width: 100%;
      margin: 0;
   }
}

/* iPhone XR, XS Max */

@media (max-width: 414px) {
   .wrapper {
      width: 100%;
      margin: 0;
   }
}

/* iPhone XS, X */
@media (max-width: 375px) {
   .wrapper {
      width: 100%;
      margin: 0;
   }

}