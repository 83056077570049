label {
   margin-top: 1.2rem;
}

.error_text {
   margin-left: 1em;
   margin-top: 0.5em;
   font-size: 0.8em !important;
}

input {
   background-color: var(--section);
   border-radius: var(--radius);
   border: solid 1px #305385;
   height: 3.9rem;
   width: 100%;
   box-sizing: border-box;
   padding-left: 2rem;
   padding-right: 0.9rem;
   color: var(--global-font);
   padding-top: 0.3rem;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   margin-top: -0.9rem;
}

input[type="date"] {
   -webkit-appearance: none;
   -moz-appearance: none;
   text-align: start;
}

input:focus-visible {
   outline: none;
}

input::placeholder {
   color: var(--global-font);
   font-size: 1.3rem;
   font-weight: 300;
}
