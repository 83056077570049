.GameOfTheWeek {
   display: flex;
   height: 8rem;
   padding: 1rem;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   gap: 2rem;
   width: 95%;
}

.GameOfTheWeek_column {
   display: flex;
   justify-content: start;
   align-items: flex-start;
   flex-direction: column;
   flex-basis: 80%;
}

.GameOfTheWeek_column-c {
   display: flex;
   justify-content: start;
   align-items: center;
   flex-direction: column;
   gap: 1rem;
   margin: 0;
   padding: 0;
   flex-basis: 80%;
}

.GameOfTheWeek_Date {
   flex-basis: 100%;
   text-align: left;
   margin: 0px;
}

.GameOfTheWeek_Status {
   display: flex;
   flex-basis: 100%;
   flex-direction: Row;
   justify-content: start;
   align-items: center;
   gap: 1rem;
   margin-top: 0.5rem;
   margin-bottom: 1rem;
}

.GameOfTheWeek_type {
   width: 100%;
   text-align: left;
}

.GameOfTheWeek_Team {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.GameOfTheWeek_body {
   padding: 0;
   margin: 0;
   /* Body 2 */
   font-family: Roboto;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.25px;
}

.line_game {
   width: 0.2rem;
   height: 5rem;
   background-color: var(--global-font);
   border-radius: 15px;
}

.GameOfTheWeek_Player {
   width: 5rem;
   margin: 0px;
   padding: 0.5rem;
   display: gird;
   background-color: var(--section);
   border-radius: 9px;
}

.GameOfTheWeek_PlayerTitle {
   width: 100%;
   padding: 0px;
   margin: 0px;
}

.-active {
   color: var(--main-green);
}

.GameOfTheWeek_vs {
   margin: 0;
}

@media (max-width: 576px) {
   .GameOfTheWeek {
      gap: 0.9rem;
   }

   .GameOfTheWeek_column-c {
      gap: 0.3rem;
   }
}

@media (max-width: 576px) {
   .GameOfTheWeek_Date {
      font-size: 1.2rem;
   }
}
