.SixthTitle {
   font-family: Roboto;
   font-size: 21px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   letter-spacing: 0.15px;
}

.negative-margin {
   margin-top: -0.69rem;
   background-color: var(--section);
}
