.infoTableCfl {
   /* width: 82%; */
   /* margin: 4.2rem 120px 0px calc(90px + 18rem); */
   display: flex;
   flex-direction: row;
   gap: 2.7rem;
   height: auto;
   transition: margin 150ms ease-in-out;
   /* margin: 4.2rem 120px 0px calc(90px + 18rem); */
   /* margin-left: calc(90px + 4.2rem); */
   margin-top: 2.7rem;
   height: 52.6rem;
   overflow: hidden;
}

.scrollShadow {
   position: sticky;
   inset: 0;
   min-height: 4.2rem;
   /* background: red; */
   pointer-events: none;
   /* border: 3px solid red; */
   width: 100%;
   background: linear-gradient(
      to bottom,
      var(--bg-color) 30%,
      rgb(20 35 56 / 0)
   );
   visibility: hidden;
   /* margin-top: 0.9rem; */
   /* margin-bottom: -1.5rem; */
}

@media (max-width: 576px) {
   .infoTableCfl {
      flex-direction: column;
      height: initial;
   }

   .scrollShadow {
      min-height: 3rem;
   }
}
