.upgames--section {
   display: flex;
   flex-direction: row;
   margin-bottom: 3.4rem;
}

@media (max-width: 1023px) {
   .upgames--section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 3.4rem;
   }
  }
 