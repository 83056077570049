.upgames {
   text-transform: uppercase;
   margin: 2.4rem;
   margin-top: 2.1rem;
}

.upgames--content {
   margin-top: -0.72rem;
}

.upgames--subcontent {
   margin-top: -0.36rem;
}
