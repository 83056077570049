.nav--btn {
   height: 100%;
   display: flex;
   align-items: center;
   border: 1px var(--main-green) solid;
   padding: 0.3rem 0.9rem;
}

.__left {
   border-top-left-radius: var(--radius);
   border-bottom-left-radius: var(--radius);
   background-color: var(--main-green);
}

.__right {
   border-top-right-radius: var(--radius);
   border-bottom-right-radius: var(--radius);
}
