.LeaderBoard {
   display: flex;
   width: 97%;
   padding: 1rem;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 0.2rem;
}

.LeaderBoard_column {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   gap: 2rem;
   flex-basis: 100%;
}

.LeaderBoard_column-c {
   display: flex;
   justify-content: start;
   align-items: center;
   flex-direction: row;
   gap: 1rem;
   flex-basis: 30%;
}

.LeaderBoard_name {
   flex-basis: 100%;
   text-align: left;
   margin: 0px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.LeaderBoard_playerData {
   width: auto;
   /* height: 20%; */
   justify-content: center;
   align-items: center;
   width: 50%;
   /* overflow: hidden; */
   /* text-overflow: ellipsis; */
}

.LeaderBoard_pts {
   height: fit-content;
   display: flex;
   flex-basis: 100%;
   flex-direction: Row;
   justify-content: start;
   align-items: center;
   gap: 0.9rem;
   margin-top: 0.5rem;
}

.LeaderBoard_body {
   padding: 0;
   margin: 0;
   /* Body 2 */
   font-family: Roboto;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.25px;
}

.line_game-h {
   width: 8rem;
   height: 1px;
   background-color: var(--global-font-o);
   border-radius: 15px;
   margin: 0.3rem 0;
}

.LeaderBoard_defTeam {
   width: 50%;
   margin: 0px;
   padding: 0.69rem;
   background-color: var(--section);
   border-radius: 9px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: start;
   border: 1px solid var(--bg-color-o2);
}

.LeaderBoard_team {
   margin: 0px;
   margin-bottom: 0.5em;
   padding: 0.3rem;
   background-color: var(--section);
   border-radius: 9px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.LeaderBoard_PlayerTitle {
   width: 100%;
   padding: 0px;
   margin: 0px;
}

.-active {
   color: var(--main-green);
   margin: 0;
}

.-noMargin {
   margin: 0;
}

.LeaderBoard_vs {
   margin: 0;
}
