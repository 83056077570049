.auth--container {
   display: flex;
   align-items: center;
   /* width: 100vw; */
   /* border: solid red 3px; */
}

.auth--subcontainer {
   position: relative;
   width: 57vw;
   height: 100vh;
   overflow: hidden;
}

.auth--img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.auth--subcontainer::before {
   /* border: 3px solid red; */
   box-shadow: inset -6.3rem 0 20rem 3rem var(--bg-color),
      inset -6rem 0 6rem 1rem var(--bg-color),
      inset -2.1rem 0 2.1rem 1rem var(--bg-color),
      inset -1rem 0 1rem 1rem var(--bg-color);
   content: "";
   position: absolute;
   top: -3rem;
   left: 0;
   bottom: -3rem;
   right: 0;
   /* border-radius: var(--radius); */
}

.auth--form {
   /* margin: auto; */
   /* vertical-align: middle; */
   width: 43vw;
   padding-right: 6rem;
   /* border: 3px solid red; */
}

.auth--back {
   display: flex;
   align-items: center;
}

.auth--arrow {
   cursor: pointer;
   color: var(--main-green);
   margin-bottom: 0.5rem;
   padding: 0 1.2rem 0 0.3rem;
   font-size: 1.5rem;
   font-weight: bolder;
}

/* Mobile style*/

/* @media (max-width: 1023px) {
   .auth--subcontainer {
      display: flex;
      width: 100vw;
   }

   .auth--subcontainer::before {
      box-shadow: none;
      content: "";
      position: absolute;
      top: -3rem;
      left: 0;
      bottom: 69%;
      right: 0;
   }
   .auth--form {
      position: fixed;
      width: 90vw;
      padding-right: 0rem;
      padding: 1rem;
      bottom: 5%;
      left: 5%;
   }
   .auth--img {
      width: 100%;
      height: 31%;
      object-fit: cover;
   }

   .auth--arrow {
      font-size: 1.2rem;
   }
} */

@media (max-width: 820px) {
   .auth--container {
      /* height: 100vh; */
      height: initial;
      flex-direction: column;
   }

   .auth--subcontainer {
      height: 30vh;
      width: 100vw;
      /* margin-bottom: 1.8rem; */
   }

   .auth--subcontainer::before {
      width: 150%;
      margin-left: -25%;
      box-shadow: inset 0 -8.1rem 20rem 3rem var(--bg-color),
         inset 0 -5.1rem 6rem 1rem var(--bg-color),
         inset 0 -3rem 2.1rem 1rem var(--bg-color),
         inset 0 -0.6rem 1rem 1rem var(--bg-color);
   }

   .auth--img {
      height: 100%;
      /* object-position: 0 6rem; */
   }

   .auth--form {
      width: 100vw;
      padding: 0 4.2rem;
   }

   .auth--arrow {
      font-size: 1.2rem;
      padding: 0 0.9rem 0 0.3rem;
   }
}

@media (max-width: 576px) {
   .auth--form {
      padding: 0 2.4rem;
   }
}
