.DefaultCardContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}
.DefaultCardContainer_Title{
    width: 100%;

}
.DeDefaultCardContainer_content{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding-right: 1rem;
}

