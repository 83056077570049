.header_form_new {
    display: flex;
    align-items: center;
}

.step_number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--main-green);
    border-radius: var(--radius);

}

.step_number span {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.step_text {
    background-color: #142338;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 0 var(--radius) var(--radius) 0;
}

.step_text p {
    margin: 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.notes_step {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 3em;
    letter-spacing: 0.15em;
    color: #FFF;
    text-align: center;
}

.footer_form_new {

    display: flex;
    justify-content: space-between;
}

.card_about {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 1px solid #5B6D86;
    background: #2E425D;
    padding: 1em;
}

.card_about p {
    margin: 0;
}

.card_price_league {
    border-radius: 9px;
    border: 1px solid #5B6D86;
    background: #162B47;
    padding: 1.5em;
    min-height: 100%;
}

.card_price_league_selected{
    border-radius: 9px;
    border: 2px solid #008285;
    background: #162B47;
    padding: 1.5em;
    min-height: 100%;
}

.card_title_league {
    color: var(--clickable, #008285);
    /* H4 */
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.25px;
}