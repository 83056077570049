.gamesOfTheWeekCFL {
   width: 33.3%;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 2rem;
}

.gamesOfTheWeekCFL-2 {
   width: 27%;
}

.gamesOfTheWeekCFL_title {
   position: relative;
   font-family: Roboto;
   font-size: 2.37rem;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   letter-spacing: -1px;
   min-height: 3.6rem;
   z-index: 1;
}

.gamesOfTheWeekCFL_CardContent {
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 1rem;
   overflow-y: scroll;
   margin-top: -1.2rem;
   /* overflow-x: hidden; */
}

.gamesOfTheWeekCFL_CardContent::-webkit-scrollbar {
   width: 8px; /* Ancho del scroll bar */
}

.gamesOfTheWeekCFL_CardContent::-webkit-scrollbar-thumb {
   background-color: var(--stroke-card); /* Color del thumb */
   border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.gamesOfTheWeekCFL_CardContent::-webkit-scrollbar-track {
   background-color: transparent; /* Fondo transparente del carril */
}

.gamesOfTheWeekCFL_CardContent::-webkit-scrollbar-thumb:hover {
   background-color: var(
      --blue-text
   ); /* Color del thumb al pasar el cursor por encima */
}

.margin-t {
   margin-top: -3.3rem;
   width: 100%;
}

.card-bottom {
   margin-bottom: 1.2rem;
}

@media (max-width: 576px) {
   .gamesOfTheWeekCFL {
      width: 100%;
      padding-left: 1rem;
      padding-right: 0.9rem;
      height: auto;
   }

   .gamesOfTheWeekCFL_title {
      font-size: 1.92rem;
   }
   /* 
   .margin-t {
      margin-top: -6rem;
   } */
}
