.ConferenceForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.ConferenceForm__select {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.ConferenceForm__addBtn {
    width: 6rem;

}

.ConferenceForm__row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: row;
    gap: 1rem;
}

.ConferenceForm__col {
    width: 70%;
    margin-left: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
}

.-selectSize {
    width: 100%;
}