.active {
   color: #008285;
   font-weight: 700;
}

.nav--item {
   padding: 0.9rem;
   text-decoration: none;
   margin: auto 0;
   color: inherit;
   font-weight: 300;
}
