.playerCFL{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.DeDefaultCardContainer_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.playerCFL__modal{
    width: 100%;
    height: auto;
}
.-P-heigth{
    height: 41vh;
}
@media (max-width: 416px){
    .-P-heigth{
        height: auto;
    }
}