.TertiarySubtitle {
   font-family: Roboto;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   letter-spacing: 0.1px;
}

.nickNameOverflow {
   text-overflow: ellipsis;
   width: 100%;
   overflow: hidden;
   white-space: nowrap;
}

.start {
   text-align: start;
}

.end {
   text-align: end;
}
