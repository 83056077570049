.login--form {
   margin-top: 1.5rem;
}

.login--forgot {
   justify-content: flex-end;
   margin-right: 0;
   margin-top: 0.75rem;
   /* margin-bottom: 1.5rem; */
   color: var(--main-green);
}

.auth-margin {
   margin-bottom: 1.8rem;
}

.signup--btn {
   margin-bottom: 1.2rem;
}
