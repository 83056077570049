.HeroCFLInfoDeadline_Season{
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: end;
    margin: 10px 0px 10px 0px;
    gap: 6px;
    
}
.HeroCFLInfoDeadline_status{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.HeroCFLInfoDeadline_seasonType{
    color: var(--main-green)
}