.banner_header_dasboard_cfl {
   /* width: 90%; */
   min-height: 18rem;
   height: 100%;
   max-height: 23.1rem;
   flex-shrink: 0;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   flex-direction: column;
   /* bg */
   background: url(../../../assets/img/HeroCFL2.jpg) no-repeat;
   background-size: 100%;
   background-position: -236px -170px;
   /* Img effect */
   /* box-shadow: -472px -105px 250px 150px #142338 inset; */
   /* margin */
   /* padding: 0px 150px 0px 0px; */
   /* margin: 56px 0px 0px 72px; */
}

/* .img_banner_dasboard {
    width: 90%;
    background-repeat: no-repeat;
    padding: 0px 150px 0px 0px;
    margin: 56px 0px 0px 72px;

} */

.text-green {
   color: var(--main-green);
}

.container_team_page {
   /* width: 87%; */
   /* height: 53.5vh; */
   overflow-y: scroll;
   overflow-x: hidden;
}

.container_team_page::-webkit-scrollbar {
   width: 8px;
   /* Ancho del scroll bar */
}

.container_team_page::-webkit-scrollbar-thumb {
   background-color: var(--stroke-card);
   /* Color del thumb */
   border-radius: 5px;
   /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.container_team_page::-webkit-scrollbar-track {
   background-color: transparent;
   /* Fondo transparente del carril */
}

.container_team_page::-webkit-scrollbar-thumb:hover {
   background-color: var(--blue-text);
   /* Color del thumb al pasar el cursor por encima */
}

.container_card_picks {
   margin: auto;
   width: 80%;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 1em;
   flex-wrap: wrap;
}

.section_team {
   margin-top: 2em;
   text-align: center;
}

.card_picks {
   width: 32%;
   padding: 0.8em 3em;
}

.card_picks_2 {
   width: 100%;
   padding: 0.8em 3em;
}

.card_pick_content {
   /* display: flex; */
   align-items: center;
   justify-content: center;
   text-align: center;
   width: 100%;
}

.av_pick_team {
   margin-left: 0.8em;
   padding: 0.7em;
   background-color: #162b47;
   border-radius: var(--radius);
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.015rem;
}

.divider_picks {
   width: 50%;
   margin: 0.9em auto;
   border-radius: var(--radius);
   border: 0.2em solid var(--global-font);
}
