.default-card {
   border-radius: var(--radius);
   border: 1px solid var(--stroke-card);
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      #122033 0%,
      #305385 100%
   );
   /* width: fit-content; */
}

.filter--btn {
   cursor: pointer;
   padding: 0.3rem 1.5rem;
   margin-bottom: 0.9rem;
   border: 1px solid var(--stroke-card);
}

.green-border {
   border: var(--main-green) 1px solid;
}

.white-border {
   border: var(--global-font) 1px solid;
}
