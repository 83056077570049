.card_packs_league_add {
   border-radius: var(--radius);
   border: 3px dashed #5b6d86;
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      #122033 0%,
      #305385 100%
   );
   width: 31.5%;
   min-height: 9.9em;
   display: flex;
   flex-direction: column;
   padding: 1em 2em;
   justify-content: center;
   align-items: center;
   flex-shrink: 0;
   /* border-width: 4px; */
}

.card_packs_league {
   border-radius: var(--radius);
   border: 3px dashed var(--clickable, #008385a6);
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      #122033 0%,
      #305385 100%
   );
   width: 31.5%;
   min-height: 9.9em;
   display: flex;
   padding: 1em 2em;
   justify-content: center;
   align-items: center;
   gap: 1.5em;
   flex-shrink: 0;
   /* flex: 1; */
   /* border-width: 4px; */
}

.card_packs_league:hover {
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      #305385 0%,
      #122033 100%
   );
   cursor: pointer;
   border: 3px solid var(--clickable, #008385a6);
}

.no_league {
   display: flex;
   width: 31.5%;
   min-height: 9em;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-bottom: 0;
   /* flex-wrap: wrap; */
}

.card_packs_league_add:hover {
   background: radial-gradient(
      223.22% 177.3% at 11.16% 16.06%,
      #305385 0%,
      #122033 100%
   );
   cursor: pointer;
}
.text_card_league {
   display: flex;
   flex-direction: column;
   gap: 0.4em;
}

.text_card_title {
   color: #477bc4;
   font-family: Roboto;
   font-size: 1.3em;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   letter-spacing: 0.1px;
}

.text_card_confe {
   color: var(--global-font, #e6ebf4);
   font-size: 0.7em;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 1.5px;
   text-transform: uppercase;
}

.text_card_week {
   color: var(--global-font);
   font-size: 0.7em;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 1.5px;
   text-transform: uppercase;
   width: 100%;
}

.info_card_league {
   display: flex;
   flex-direction: column;
   gap: 0.5em;
}

.info_status {
   border-radius: var(--radius);
   background: var(--wrapper, #162b47);
   color: var(--global-font, #e6ebf4);
   padding: 0.7em;
   /* OVERLINE */
   font-size: 0.7em;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 1.5px;
   text-transform: uppercase;
}

.info_points {
   display: flex;
   color: var(--clickable, #008285);
   font-size: 0.9em;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   letter-spacing: 1.5px;
   text-transform: uppercase;
   width: auto;
   justify-content: end;
   padding-right: 0.3rem;
}

@media screen and (max-width: 1399px) {
   .card_packs_league,
   .card_packs_league_add {
      width: 48.5%;
   }
}

@media screen and (max-width: 988px) {
   .card_packs_league,
   .card_packs_league_add,
   .no_league {
      width: 100%;
   }
}
