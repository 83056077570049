.spinner--wrapper {
   margin-top: 1.5rem;
   display: flex;
   justify-content: center;
   width: 200px;
   height: 6rem;
   position: relative;
}

.circle {
   width: 30px;
   height: 30px;
   position: absolute;
   border-radius: 50%;
   background-color: var(--main-green);
   left: 15%;
   transform-origin: 50%;
   animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
   0% {
      top: 60px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
   }

   40% {
      height: 20px;
      border-radius: 50%;
      transform: scaleX(1);
   }

   100% {
      top: 0%;
   }
}

.circle:nth-child(2) {
   left: 42.5%;
   animation-delay: 0.2s;
}

.circle:nth-child(3) {
   left: auto;
   right: 15%;
   animation-delay: 0.3s;
}

.shadow {
   width: 30px;
   height: 4px;
   border-radius: 50%;
   background-color: rgba(230, 235, 244, 0.12);
   position: absolute;
   top: 62px;
   transform-origin: 50%;
   z-index: -1;
   left: 15%;
   filter: blur(1px);
   animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
   0% {
      transform: scaleX(1.5);
   }

   40% {
      transform: scaleX(1);
      opacity: 0.7;
   }

   100% {
      transform: scaleX(0.2);
      opacity: 0.4;
   }
}

.shadow:nth-child(4) {
   left: 42.5%;
   animation-delay: 0.2s;
}

.shadow:nth-child(5) {
   left: auto;
   right: 15%;
   animation-delay: 0.3s;
}
