.PlayerCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
}

.PlayerCard__name {
    width: 15rem;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    padding: 0;
    margin: 0;
}

.PlayerCard__delete {
    width: 5rem;
    display: flex;
    padding: 5px 13px;
    align-items: flex-start;
    border-radius: 9px;
    background: var(--wrapper, #162B47);
}

.PlayerCard__deletText {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    margin-bottom: 0px;
}