.InfoPanelCFL {
   height: 100vh;
   display: flex;
   flex-direction: row;
   align-items: center;
   overflow: hidden;
   gap: 1rem;
}
.infoPanelCFL_Col {
   width: 33%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   gap: 2rem;
   margin: 0 !important;
   overflow-x: hidden;
   overflow-y: scroll;
}
.infoPanelCFL_Col::-webkit-scrollbar {
   width: 8px; /* Ancho del scroll bar */
}

.infoPanelCFL_Col::-webkit-scrollbar-thumb {
   background-color: var(--stroke-card); /* Color del thumb */
   border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.infoPanelCFL_Col::-webkit-scrollbar-track {
   background-color: transparent; /* Fondo transparente del carril */
}

.infoPanelCFL_Col::-webkit-scrollbar-thumb:hover {
   background-color: var(
      --blue-text
   ); /* Color del thumb al pasar el cursor por encima */
}

@media (max-width: 416px) {
   .InfoPanelCFL {
      height: auto;
      width: 100%;
      flex-direction: column;
   }
   .infoPanelCFL_Col {
      width: 90%;
      height: auto;
      margin: 0px;
      overflow: unset;
      height: inherit;
   }
}
