.CardNew {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 5rem;
}
.CardNew_icon{
    width: 31px;
    height: 31px;
    align-self: center;
    background: url(../../../assets/svg/data_saver_on.svg) no-repeat center;
}