.DinamicBody{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DinamicBody_picker{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
    gap: 2rem;
}
.DinamicBody_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: 0%;
    gap: 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.DinamicBody_Btn{
    padding: 1rem;
}

.DinamicBody_Btn:hover{
    opacity: 100%;
    transition: 0.5s;
}
.-selec{
    opacity: 60%;
}
.DinamicBody_content::-webkit-scrollbar {
    width: 8px; /* Ancho del scroll bar */
  }
  
  .DinamicBody_content::-webkit-scrollbar-thumb {
    background-color: var(--stroke-card);/* Color del thumb */
    border-radius: 5px; /* Radio de esquinas para que el thumb tenga bordes redondeados */
  }
  
  .DinamicBody_content::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente del carril */
  }
  
  .DinamicBody_content::-webkit-scrollbar-thumb:hover {
    background-color: var(--blue-text); /* Color del thumb al pasar el cursor por encima */
  }

.-scale-up {
    animation: -scale-up 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes -scale-up {
    0% {
        flex-basis: 0%;
        transform-origin: 50% 50%;
        opacity: 1;
    }

    100% {
        flex-basis: 90%;
        transform-origin: 100% 50%;
        opacity: 1;
    }
}

.-scale-down {
    animation: -scale-down 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes -scale-down {
    0% {
        flex-basis: 90%;
        transform-origin: 100% 50%;
        opacity: 1;
    }

    100% {
        flex-basis: 0%;
        transform-origin: 50% 50%;
        opacity: 1;
    }
}