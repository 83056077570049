.CFL {
   display: flex;
   flex-direction: row;
   position: relative;
   height: max-content;
   /* overflow: hidden; */
}

.CFL-content {
   background-color: #142338;
   flex-basis: 100%;
   transition: margin 150ms ease-in-out;
   /* margin: 0rem 0rem 0rem 2rem; */
}

.CFL-content--admin {
   flex-basis: 100%;
   overflow: hidden;
   transition: margin 150ms ease-in-out;
}

.default-margin {
   margin: 4.2rem 8.1vw 0px calc(var(--aside-width) + 4.2vw);
   /* margin-left: 18rem; */
   /* width: 66vw; */
}

.default-margin--admin {
   margin: 0.3rem 3vw 0px calc(var(--aside-width) + 3vw);
}

/* .opened-aside {
   margin-left: calc(19vw + 18rem);
   width: 81vw;
}*/

.closed-aside {
   margin-left: 8.1vw;
}

@media (max-width: 576px) {
   .CFL {
      display: contents;
   }

   .CFL-content {
      background-color: #142338;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin: 0rem 1.5rem 0rem 3rem;
      margin: 0;
      margin-left: 2.7rem;
   }
}
