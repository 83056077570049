.border_separador {
    border-left: 2px var(--global-font) solid;
}

.container_info_card_games {
    display: flex;
    align-content: space-around;
    padding: 0.5em;
}

.info_card_games_date {
    flex: 0 0 auto;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0.5em;
}

.info_card_games_date p {
    display: block;
    margin-left: 0.5em;
}

.info_card_games_detail {
    padding: 0.4em;
    margin-bottom: 0.4em;
    border-radius: 0.5625rem;
    background: var(--card-bg, radial-gradient(223.22% 177.30% at 11.16% 16.06%, #122033 0%, #305385 100%));
}

.DefaultCardContainerGames {
    height: 99%;
    padding-right: 1rem;
    overflow-x: hidden;
}

.DefaultCardContainerGames::-webkit-scrollbar {
    width: 8px;
    /* Ancho del scroll bar */
}

.DefaultCardContainerGames::-webkit-scrollbar-thumb {
    background-color: var(--stroke-card);
    /* Color del thumb */
    border-radius: 5px;
    /* Radio de esquinas para que el thumb tenga bordes redondeados */
}

.DefaultCardContainerGames::-webkit-scrollbar-track {
    background-color: transparent;
    /* Fondo transparente del carril */
}

.DefaultCardContainerGames::-webkit-scrollbar-thumb:hover {
    background-color: var(--blue-text);
    /* Color del thumb al pasar el cursor por encima */
}

.info_games_teams {
    font-family: Roboto;
    border-radius: var(--radius);
    background: var(--wrapper, #162B47);
    min-width: 80%;
    max-width: 80%;

    padding: 0.4em;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 416px) {
    .container_info_card_games {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0
    }

    .info_card_games_date {
        width: 90%;
        flex-direction: row;
        margin: 0;
        gap: 1rem;
    }
    .border_separador {
        border-left: 0px var(--global-font) solid;

        margin-top: 1rem;
    }
    .-margin-top-2{
        margin-top: 2rem;
    }
}