.link-atom {
   display: flex;
}

.signup--btn {
   margin-top: 1.2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 3.9rem;
   border-radius: var(--radius);
   border: 2px solid var(--main-green);
   font-size: 1.2rem;
   font-weight: 700;
   color: var(--main-green);
   background-color: var(--section);
}

.started--btn {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--main-green);
   border: none;
   border-radius: var(--radius);
   padding-top: 0.3rem;
   margin-top: 1.5rem;
   font-size: 1.2rem;
   height: 3.9rem;
   color: var(--global-font);
   font-weight: 700;
   width: 36rem;
   /* margin: auto 0; */
   /* max-width: 60rem; */
}

.hero--btn {
   height: 3.9rem;
   /* width: 100%; */
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--main-green);
   border: none;
   border-radius: var(--radius);
   padding-top: 0.3rem;
   margin-top: 1.5rem;
   font-size: 1.2rem;
   height: 3.9rem;
   color: var(--global-font);
   font-weight: 700;
}
