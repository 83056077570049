.Aside {
   display: flex;
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   /* bottom: 0; */
   /* min-height: 100vh;*/
   height: 100%;
   padding: 0px 16px;
   flex-direction: column;
   align-items: center;
   gap: 16px;
   background-color: var(--section);
   overflow: hidden;
   width: var(--aside-width);
}

.Aside_AsideTop {
   display: flex;
   width: 100%;
   height: 152px;
   justify-content: space-around;
   align-items: center;
   align-content: center;
   gap: 10px;
   flex-wrap: wrap;
}

.Aside_Logo {
   width: 121px;
   height: 31px;
   flex-shrink: 0;
   background: url(../../../assets/img/logo_v2.png);
}

.Aside_ico {
   /* margin-left: 2rem; */
   color: aliceblue;
   width: 38px;
   height: 38px;
   background-image: url(../../../assets/svg/Aside_open.svg);
   cursor: pointer;
}

.Aside_AsideMid {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 96%;
   gap: 21px;
}

.Aside_AsideBot {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 21px;
   gap: 21px;
}

.Aside_Nav {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 45px;
}

.Aside_navlink {
   font-family: Roboto;
   font-size: 21px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}

/* .arrow {
   margin-right: 3rem;
} */

.Aside_navIcons {
   display: flex;
   justify-content: center;
   align-items: center;
   justify-items: center;
}

.-open {
   width: 4.2rem;
   animation: -open 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes -open {
   0% {
      flex-basis: 20%;
      transform-origin: 100% 50%;
      opacity: 1;
   }

   100% {
      flex-basis: 5%;
      transform-origin: 50% 50%;
      opacity: 1;
   }
}

.-close {
   animation: -close 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes -close {
   0% {
      flex-basis: 5%;
      transform-origin: 50% 50%;
      opacity: 1;
   }

   100% {
      flex-basis: 20%;
      transform-origin: 100% 50%;
      opacity: 1;
   }
}

.slide-in-left {
   animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
   0% {
      transform: translateX(-200px);
      width: 0%;
      opacity: 1;
   }

   100% {
      transform: translateX(0);

      opacity: 1;
   }
}

.slide-out-left {
   animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
   visibility: hidden;
}

@keyframes slide-out-left {
   0% {
      transform: translateX(0);
      width: 0%;
      opacity: 1;
   }

   100% {
      transform: translateX(-200px);
      width: 0%;
      opacity: 1;
   }
}

@media (max-width: 576px) {
   .Aside {
      z-index: 6;
   }
}


@media (max-width: 1380px) {
   .Aside_Nav{
      gap: 15px;
   }
}
