.AsideLastGame_LastMatch {
   display: flex;
   width: 100%;
   /* padding: 0px 16px; */
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 10px;
   flex-shrink: 0;
   border-radius: 9px;
   border: 1px solid var(--stroke-card, #305385);
   margin-top: 0.3rem;
}

.AsideLastGame_GameResult {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   gap: 8px;
}

.AsideLastGame_Caption {
   /* Caption */
   color: #008285;
}
.AsideLastGame_Caption-red {
   color: #ed1b1b;
   text-align: right;
}

.AsideLastGame_TeamsContainer {
   max-width: 81%;
   width: 72%;
   height: 46px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   flex-shrink: 0;
   font-size: 0.51rem;
   text-align: center;
   padding: 1.2rem 0;
}

.AsideLastGame_TeamA {
   display: flex;
   /* width: 121px; */
   flex-direction: column;
   align-items: flex-start;
   gap: 3px;
   width: 48%;
}

.AsideLastGame_Vs {
   display: flex;
   /* width: 23px; */
   height: 30px;
   flex-direction: column;
   justify-content: center;
   flex-shrink: 0;
}

.AsideLastGame_TeamB {
   display: flex;
   /* width: 121px; */
   flex-direction: column;
   align-items: flex-end;
   gap: 3px;
   width: 48%;
}
